import React, { useState, useEffect, useContext, createContext } from "react";
import { httpClient } from "./Api";
import { Cookies } from "react-cookie";
import { useRouter } from "next/router";
import { notification } from "./utilities";

interface authContext {
  isLoadingUser: boolean;
  isLoading: boolean;
  authUser: any;
  error: string;
  setAuthUser: React.Dispatch<any>;
  getAuthUser: () => void;
  userLogin: (data: any, callbackFun: any) => void;
  userLogout: () => void;
  userSignup: (data: any, callbackFun: any) => void;
  userSignOut: (callbackFun: any) => void;
}

const authContext = createContext<authContext>(undefined);

// Provider component that wraps app and makes auth object ..
// ... available to any child component that calls useAuth().

export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = () => {
  const [authUser, setAuthUser] = useState(null);
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const router = useRouter();

  const fetchStart = () => {
    setLoading(true);
    setError("");
  };

  const fetchSuccess = () => {
    setLoading(false);
    setError("");
  };

  const fetchError = (error) => {
    setLoading(false);
    setError(error);
  };

  const userLogin = (data, callbackFun) => {
    var split = data.username.split("@");

    fetchStart();

    switch (split[1]) {
      case "boss":
        httpClient
          .post("login/boss", data)
          .then(({ data }) => {
            if (data.success) {
              fetchSuccess();
              // httpClient.defaults.headers.common["Authorization"] =
              //   "Bearer " + data.token.access_token;
              // const cookies = new Cookies();
              // cookies.set("token", data.token.access_token);
              getAuthUser();
              notification("เข้าสู่ระบบสำเร็จ", "", "green");
              if (callbackFun) callbackFun();
            } else {
              fetchError(data.message);
              notification("Username หรือ Password ไม่ถูกต้อง", "", "red");
            }
          })
          .catch(function (error) {
            fetchError(error.message);
          });
        break;

      case "can":
        httpClient
          .post("login/candidate", data)
          .then(({ data }) => {
            if (data.success) {
              fetchSuccess();
              getAuthUser();
              notification("เข้าสู่ระบบสำเร็จ", "", "green");
              if (callbackFun) callbackFun();
            } else if (data.message === "locked user") {
              notification("บัญชีนี้ถูกปิดใช้งานอยู่", "", "red");
            } else {
              fetchError(data.message);
              notification("Username หรือ Password ไม่ถูกต้อง", "", "red");
            }
          })
          .catch(function (error) {
            fetchError(error.message);
          });
        break;

      case "ca":
        httpClient
          .post("login/calias", data)
          .then(({ data }) => {
            if (data.success) {
              fetchSuccess();
              getAuthUser();
              notification("เข้าสู่ระบบสำเร็จ", "", "green");
              if (callbackFun) callbackFun();
            } else if (data.message === "locked user") {
              notification("บัญชีนี้ถูกปิดใช้งานอยู่", "", "red");
            } else {
              fetchError(data.message);
              notification("Username หรือ Password ไม่ถูกต้อง", "", "red");
            }
          })
          .catch(function (error) {
            fetchError(error.message);
          });
        break;

      default:
        httpClient
          .post("login/mainstay", data)
          .then(({ data }) => {
            if (data.success) {
              fetchSuccess();
              getAuthUser();
              notification("เข้าสู่ระบบสำเร็จ", "", "green");
              if (callbackFun) callbackFun();
            } else if (data.message === "locked user") {
              notification("บัญชีนี้ถูกปิดใช้งานอยู่", "", "red");
            } else {
              fetchError(data.message);
              notification("Username หรือ Password ไม่ถูกต้อง", "", "red");
            }
          })
          .catch(function (error) {
            fetchError(error.message);
          });
        break;
    }
  };

  const userLogout = () => {
    fetchStart();
    httpClient
      .get("logout")
      .then(({ data }) => {
        if (data.success) {
          fetchSuccess();
          setAuthUser(false);
          httpClient.defaults.headers.common["Authorization"] = "";
          const cookies = new Cookies();
          cookies.remove("token");
          router.push("/");
          notification("ออกจากระบบสำเร็จ", "", "green");
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const userSignup = (data, callbackFun) => {
    fetchStart();
    httpClient
      .post("auth/register", data)
      .then(({ data }) => {
        if (data.result) {
          fetchSuccess();
          httpClient.defaults.headers.common["Authorization"] =
            "Bearer " + data.token.access_token;
          const cookies = new Cookies();
          cookies.set("token", data.token.access_token);
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const userSignOut = (callbackFun) => {
    fetchStart();
    httpClient
      .post("auth/logout")
      .then(({ data }) => {
        if (data.result) {
          fetchSuccess();
          setAuthUser(false);
          httpClient.defaults.headers.common["Authorization"] = "";
          const cookies = new Cookies();
          cookies.remove("token");
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const getAuthUser = () => {
    httpClient
      .get("checkCk")
      .then(({ data }) => {
        setAuthUser(data.loggedInData);
        if (data.loggedInData) {
          if (data.loggedInData.role === "candidate") {
            httpClient
              .get("/candidate/" + data.loggedInData.cid, {
                withCredentials: true,
              })
              .then((res2) => {
                if (res2.data.candidateData.forceLogout == true) {
                  userLogout();
                } else {
                  setAuthUser(data.loggedInData);
                }
              })
              .catch((err) => {
                notification(
                  "เชื่อมต่อเซิฟเวอร์ไม่สำเร็จ",
                  "กรุณาลองใหม่อีกครั้ง",
                  "red"
                );
                console.log(err);
                userLogout();
              });
          } else if (data.loggedInData.role === "calias") {
            httpClient
              .get("/calias/" + data.loggedInData.id, {
                withCredentials: true,
              })
              .then((res2) => {
                if (res2.data.caliasData.forceLogout == true) {
                  userLogout();
                } else {
                  setAuthUser(data.loggedInData);
                }
              })
              .catch((err) => {
                notification(
                  "เชื่อมต่อเซิฟเวอร์ไม่สำเร็จ",
                  "กรุณาลองใหม่อีกครั้ง",
                  "red"
                );
                console.log(err);
                userLogout();
              });
          } else if (data.loggedInData.role === "mainstay") {
            httpClient
              .get("/mainstay/" + data.loggedInData.id, {
                withCredentials: true,
              })
              .then((res2) => {
                if (res2.data.mainstayData.forceLogout == true) {
                  userLogout();
                } else {
                  setAuthUser(data.loggedInData);
                }
              })
              .catch((err) => {
                notification(
                  "เชื่อมต่อเซิฟเวอร์ไม่สำเร็จ",
                  "กรุณาลองใหม่อีกครั้ง",
                  "red"
                );
                console.log(err);
                userLogout();
              });
          } else {
            setLoadingUser(false);
          }
        }
        setLoadingUser(false);
      })
      .catch(function (error) {
        httpClient.defaults.headers.common["Authorization"] = "";
        fetchError(error.message);
      });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  // useEffect(() => {
  //   const cookies = new Cookies();
  //   const token = cookies.get("token");
  //   httpClient.defaults.headers.common["Authorization"] = "Bearer " + token;

  //   httpClient
  //     .get("checkCk")
  //     .then(({ data }) => {
  //       setAuthUser(data.loggedInData);
  //       if (data.loggedInData) {
  //         if (data.loggedInData.role === "candidate") {
  //           httpClient
  //             .get("/candidate/" + data.loggedInData.cid, {
  //               withCredentials: true,
  //             })
  //             .then((res2) => {
  //               if (res2.data.candidateData.forceLogout == true) {
  //                 userLogout();
  //               } else {
  //                 setAuthUser(data.loggedInData);
  //               }
  //             })
  //             .catch((err) => {
  //               notification(
  //                 "เชื่อมต่อเซิฟเวอร์ไม่สำเร็จ",
  //                 "กรุณาลองใหม่อีกครั้ง",
  //                 "red"
  //               );
  //               console.log(err);
  //             });
  //         } else if (data.loggedInData.role === "calias") {
  //           httpClient
  //             .get("/calias/" + data.loggedInData.id, {
  //               withCredentials: true,
  //             })
  //             .then((res2) => {
  //               if (res2.data.caliasData.forceLogout == true) {
  //                 userLogout();
  //               } else {
  //                 setAuthUser(data.loggedInData);
  //               }
  //             })
  //             .catch((err) => {
  //               notification(
  //                 "เชื่อมต่อเซิฟเวอร์ไม่สำเร็จ",
  //                 "กรุณาลองใหม่อีกครั้ง",
  //                 "red"
  //               );
  //               console.log(err);
  //             });
  //         } else {
  //           setLoadingUser(false);
  //         }
  //       }

  //       setLoadingUser(false);
  //     })
  //     .catch(function (error) {
  //       cookies.remove("token");
  //       httpClient.defaults.headers.common["Authorization"] = "";
  //       setLoadingUser(false);
  //     });
  // }, []);

  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    error,
    setAuthUser,
    getAuthUser,
    userLogin,
    userLogout,
    userSignup,
    userSignOut,
  };
};

export const isUnRestrictedRoute = (pathname) => {
  return (
    pathname === "/login" ||
    pathname === "/signup" ||
    pathname === "/forgot-password" ||
    pathname === "/reset-password"
  );
};
