import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";

import {
  AppShell,
  Header,
  Navbar,
  Group,
  Code,
  ScrollArea,
  createStyles,
  NavLink,
  ThemeIcon,
  Modal,
} from "@mantine/core";

import { isUnRestrictedRoute, useAuth } from "../util/use-auth";

import HeaderContentBoss from "./boss/Header";
import SidebarContentBoss from "./boss/Sidebar";
import HeaderContentCandidate from "./candidate/Header";
import SidebarContentCandidate from "./candidate/Sidebar";
import HeaderContentMainstay from "./mainstay/Header";
import SidebarContentMainstay from "./mainstay/Sidebar";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";
import { updateStatusIdle } from "@/redux/actions";

// import { UserButton } from "../UserButton/UserButton";
// import { LinksGroup } from "../NavbarLinksGroup/NavbarLinksGroup";
// import { Logo } from "./Logo";

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  content: {
    padding: "20px",
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

export default function NavbarNested({ children }) {
  const dispatch = useDispatch();

  const { classes } = useStyles();
  const { authUser, isLoadingUser, error, userLogout, getAuthUser } = useAuth();
  const router = useRouter();
  const [openNav, setOpenNav] = useState(false);

  const onIdle = () => {
    if (!isUnRestrictedRoute(router.pathname)) {
      userLogout();
      dispatch(updateStatusIdle(true));
    } else null;
  };
  const idleTimer = useIdleTimer({
    timeout: parseInt(process.env.NEXT_PUBLIC_IDLE_TIMEOUT) * 60000,
    onIdle,
  });

  useEffect(() => {
    if (!isLoadingUser) {
      if (!authUser && !isUnRestrictedRoute(router.pathname)) {
        router.push("/login").then((r) => r);
      } else if (authUser && isUnRestrictedRoute(router.pathname)) {
        router.push("/").then((r) => r);
      }
    }
  }, [authUser, isLoadingUser, router.pathname]);

  useEffect(() => {
    getAuthUser();
    setOpenNav(false);
  }, [router.pathname]);

  //   const links = mockdata.map((item) => (
  //     <LinksGroup {...item} key={item.label} />
  //   ));

  return isUnRestrictedRoute(router.pathname) ? (
    children
  ) : authUser &&
    (authUser.role === "boss" ||
      authUser.role === "candidate" ||
      authUser.role === "calias" ||
      authUser.role === "mainstay") ? (
    <>
      <AppShell
        padding="md"
        navbarOffsetBreakpoint="sm"
        navbar={
          authUser.role === "boss" ? (
            <SidebarContentBoss openNav={openNav} authUser={authUser} />
          ) : authUser.role === "candidate" || authUser.role === "calias" ? (
            <SidebarContentCandidate openNav={openNav} authUser={authUser} />
          ) : authUser.role === "mainstay" ? (
            <SidebarContentMainstay openNav={openNav} authUser={authUser} />
          ) : null
        }
        header={
          authUser.role === "boss" ? (
            <HeaderContentBoss
              authUser={authUser}
              userLogout={userLogout}
              openNav={openNav}
              setOpenNav={setOpenNav}
            />
          ) : authUser.role === "candidate" || authUser.role === "calias" ? (
            <HeaderContentCandidate
              authUser={authUser}
              userLogout={userLogout}
              openNav={openNav}
              setOpenNav={setOpenNav}
            />
          ) : authUser.role === "mainstay" ? (
            <HeaderContentMainstay
              authUser={authUser}
              userLogout={userLogout}
              openNav={openNav}
              setOpenNav={setOpenNav}
            />
          ) : null
        }
        styles={(theme) => ({
          main: {
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
        })}
      >
        <div className={classes.content}>{children}</div>
      </AppShell>
    </>
  ) : null;
}
