import { combineReducers } from "redux";
import Settings from "./Settings";
import Common from "./Common";
import Test from "./Test";
import Status from "./Status";

const reducers = combineReducers({
  // settings: Settings,
  // common: Common,
  // test: Test,
  status: Status,
});

export default reducers;
