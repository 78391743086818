import "../styles/globals.css";
import "antd/dist/antd.css";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";

import { wrapper } from "../redux/store";

import { AuthProvider } from "../util/use-auth";
import Layout from "../Layout";

const Page = ({ Component, pageProps, store }) => {
  return (
    // <Provider store={createStore(reducers)}>
    <AuthProvider>
      {/* <LocaleProvider> */}
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          /** Put your mantine theme override here */
          fontFamily: "Niramit, sans-serif",
          colorScheme: "light",
          headings: { fontFamily: "Niramit, sans-serif" },
        }}
      >
        <NotificationsProvider position="top-center" zIndex={2077}>
          <ModalsProvider>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </ModalsProvider>
        </NotificationsProvider>
      </MantineProvider>
      {/* </LocaleProvider> */}
    </AuthProvider>
    // </Provider>
  );
};

export default wrapper.withRedux(Page);
