import { UPDATE_STATUS_IDLE } from "../actions/Status";

const INIT_STATE = {
  idle: false,
};

const statusReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_STATUS_IDLE:
      return {
        ...state,
        idle: action.idle,
      };
    default:
      return {
        ...state,
      };
  }
};

export default statusReducer;
