import { Navbar, NavLink, ThemeIcon } from "@mantine/core";
import { TbDashboard, TbUsers, TbUserPlus, TbList } from "react-icons/tb";
import Link from "next/link";
import router from "next/router";
import React, { useState } from "react";

type Props = {
  openNav: boolean;
  authUser: {
    username: string;
    id: string;
    role: string;
    province: string;
    electorate: string;
    permission: {
      caPerm: number[];
      hmPerm: number[];
      mPerm: number[];
      hhPerm: number[];
    };
  };
};

const SidebarContentMainstay = ({ authUser, openNav }: Props) => {
  return (
    <Navbar
      hiddenBreakpoint="sm"
      hidden={!openNav}
      width={{ base: 300 }}
      height="100%"
      p="sm"
    >
      <Link href="/" passHref>
        <NavLink
          label="หน้าแรก"
          icon={
            <ThemeIcon variant="light" size={30}>
              <TbDashboard size={18} />
            </ThemeIcon>
          }
          active={router.pathname === "/"}
        ></NavLink>
      </Link>

      <NavLink
        defaultOpened
        label="ผู้มีสิทธิ์"
        icon={
          <ThemeIcon variant="light" size={30}>
            <TbUsers size={18} />
          </ThemeIcon>
        }
        childrenOffset={42}
      >
        <Link href="/mainstay/househeaderManage/list" passHref>
          <NavLink
            label="รายชื่อผู้มีสิทธิ์"
            active={router.pathname === "/mainstay/househeaderManage/list"}
            icon={
              <ThemeIcon variant="light" size={24}>
                <TbList size={14} />
              </ThemeIcon>
            }
          />
        </Link>

        <Link href="/mainstay/househeaderManage/add" passHref>
          <NavLink
            label="เพิ่มผู้มีสิทธิ์"
            active={router.pathname === "/mainstay/househeaderManage/add"}
            icon={
              <ThemeIcon variant="light" size={24}>
                <TbUserPlus size={14} />
              </ThemeIcon>
            }
          />
        </Link>
        <Link href="/mainstay/househeaderManage/addScan" passHref>
          <NavLink
            label="เพิ่มผู้มีสิทธิ์(สแกนบัตร)"
            active={router.pathname === "/mainstay/househeaderManage/addScan"}
            icon={
              <ThemeIcon variant="light" size={24}>
                <TbUserPlus size={14} />
              </ThemeIcon>
            }
          />
        </Link>
      </NavLink>
    </Navbar>
  );
};

export default SidebarContentMainstay;
