import { Navbar, NavLink, ScrollArea, ThemeIcon } from "@mantine/core";
import {
  TbDashboard,
  TbUsers,
  TbUserPlus,
  TbList,
  TbCake,
  TbId,
  TbDeviceMobile,
  TbDots,
  TbBook,
} from "react-icons/tb";
import Link from "next/link";
import router from "next/router";
import React, { useState } from "react";

type Props = {
  openNav: boolean;
  authUser: {
    username: string;
    id: string;
    role: string;
    province: string;
    electorate: string;
    permission: {
      caPerm: number[];
      hmPerm: number[];
      mPerm: number[];
      hhPerm: number[];
    };
  };
};

const SidebarContentCandidate = ({ authUser, openNav }: Props) => {
  return (
    <Navbar
      hiddenBreakpoint="sm"
      hidden={!openNav}
      width={{ base: 300 }}
      height="100%"
      p="sm"
    >
      <ScrollArea>
        <Link href="/" passHref>
          <NavLink
            label="หน้าแรก"
            icon={
              <ThemeIcon variant="light" size={30}>
                <TbDashboard size={18} />
              </ThemeIcon>
            }
            active={router.pathname === "/"}
          ></NavLink>
        </Link>
        {/* <Link href="/advanceSearch" passHref>
          <NavLink
            label="ค้นหา"
            icon={
              <ThemeIcon variant="light" size={30}>
                <TbDashboard size={18} />
              </ThemeIcon>
            }
            active={router.pathname === "/"}
          ></NavLink>
        </Link> */}
        {authUser.role === "candidate" ||
        (authUser &&
          (authUser.permission.caPerm.includes(1) ||
            authUser.permission.caPerm.includes(2))) ? (
          <NavLink
            defaultOpened
            label="ผู้ช่วย"
            icon={
              <ThemeIcon variant="light" size={30}>
                <TbUsers size={18} />
              </ThemeIcon>
            }
            childrenOffset={42}
          >
            {authUser.role === "candidate" ||
            (authUser && authUser.permission.caPerm.includes(1)) ? (
              <Link href="/candidate/caliasManage/list" passHref>
                <NavLink
                  label="รายชื่อผู้ช่วย"
                  active={router.pathname === "/candidate/caliasManage/list"}
                  icon={
                    <ThemeIcon variant="light" size={24}>
                      <TbList size={14} />
                    </ThemeIcon>
                  }
                />
              </Link>
            ) : null}
            {authUser.role === "candidate" ||
            (authUser && authUser.permission.caPerm.includes(2)) ? (
              <Link href="/candidate/caliasManage/add" passHref>
                <NavLink
                  label="เพิ่มผู้ช่วย"
                  active={router.pathname === "/candidate/caliasManage/add"}
                  icon={
                    <ThemeIcon variant="light" size={24}>
                      <TbUserPlus size={14} />
                    </ThemeIcon>
                  }
                />
              </Link>
            ) : null}
          </NavLink>
        ) : null}
        {authUser.role === "candidate" ||
        (authUser &&
          (authUser.permission.hmPerm.includes(1) ||
            authUser.permission.hmPerm.includes(2))) ? (
          <NavLink
            defaultOpened
            label="ทีมนำ"
            icon={
              <ThemeIcon variant="light" size={30}>
                <TbUsers size={18} />
              </ThemeIcon>
            }
            childrenOffset={42}
          >
            {authUser.role === "candidate" ||
            (authUser && authUser.permission.hmPerm.includes(1)) ? (
              <Link href="/candidate/headmainstayManage/list" passHref>
                <NavLink
                  label="รายชื่อทีมนำ"
                  active={
                    router.pathname === "/candidate/headmainstayManage/list"
                  }
                  icon={
                    <ThemeIcon variant="light" size={24}>
                      <TbList size={14} />
                    </ThemeIcon>
                  }
                />
              </Link>
            ) : null}
            {authUser.role === "candidate" ||
            (authUser && authUser.permission.hmPerm.includes(2)) ? (
              <Link href="/candidate/headmainstayManage/add" passHref>
                <NavLink
                  label="เพิ่มทีมนำ"
                  active={
                    router.pathname === "/candidate/headmainstayManage/add"
                  }
                  icon={
                    <ThemeIcon variant="light" size={24}>
                      <TbUserPlus size={14} />
                    </ThemeIcon>
                  }
                />
              </Link>
            ) : null}
          </NavLink>
        ) : null}

        {authUser.role === "candidate" ||
        (authUser &&
          (authUser.permission.mPerm.includes(1) ||
            authUser.permission.mPerm.includes(2))) ? (
          <NavLink
            defaultOpened
            label="ทีมทำ"
            icon={
              <ThemeIcon variant="light" size={30}>
                <TbUsers size={18} />
              </ThemeIcon>
            }
            childrenOffset={42}
          >
            {authUser.role === "candidate" ||
            (authUser && authUser.permission.mPerm.includes(1)) ? (
              <>
                <Link
                  href="/candidate/mainstayManage/list?page=1&sort=created_at&order=desc"
                  passHref
                >
                  <NavLink
                    label="รายชื่อทีมทำ"
                    active={
                      router.pathname === "/candidate/mainstayManage/list"
                    }
                    icon={
                      <ThemeIcon variant="light" size={24}>
                        <TbList size={14} />
                      </ThemeIcon>
                    }
                  />
                </Link>
                <Link
                  href="/candidate/mainstayManage/birthday?page=1&sort=created_at&order=desc"
                  passHref
                >
                  <NavLink
                    label="รายชื่อทีมทำที่เกิดเดือนนี้"
                    active={
                      router.pathname === "/candidate/mainstayManage/birthday"
                    }
                    icon={
                      <ThemeIcon variant="light" size={24}>
                        <TbCake size={14} />
                      </ThemeIcon>
                    }
                  />
                </Link>
              </>
            ) : null}
            {authUser.role === "candidate" ||
            (authUser && authUser.permission.mPerm.includes(2)) ? (
              <>
                <Link href="/candidate/mainstayManage/add" passHref>
                  <NavLink
                    label="เพิ่มทีมทำ(เสียบบัตร)"
                    active={router.pathname === "/candidate/mainstayManage/add"}
                    icon={
                      <ThemeIcon variant="light" size={24}>
                        <TbId size={14} />
                      </ThemeIcon>
                    }
                  />
                </Link>
                <Link href="/candidate/mainstayManage/addScan" passHref>
                  <NavLink
                    label="เพิ่มทีมทำ(สแกนบัตร)"
                    active={
                      router.pathname === "/candidate/mainstayManage/addScan"
                    }
                    icon={
                      <ThemeIcon variant="light" size={24}>
                        <TbDeviceMobile size={14} />
                      </ThemeIcon>
                    }
                  />
                </Link>
              </>
            ) : null}
          </NavLink>
        ) : null}

        {authUser.role === "candidate" ||
        (authUser &&
          (authUser.permission.hhPerm.includes(1) ||
            authUser.permission.hhPerm.includes(2))) ? (
          <NavLink
            defaultOpened
            label="ผู้มีสิทธิ์"
            icon={
              <ThemeIcon variant="light" size={30}>
                <TbUsers size={18} />
              </ThemeIcon>
            }
            childrenOffset={42}
          >
            {authUser.role === "candidate" ||
            (authUser && authUser.permission.hhPerm.includes(1)) ? (
              <>
                <Link
                  href="/candidate/househeaderManage/listAll?page=1&sort=created_at&order=desc"
                  passHref
                >
                  <NavLink
                    label="รายชื่อผู้มีสิทธิ์"
                    active={
                      router.pathname === "/candidate/househeaderManage/listAll"
                    }
                    icon={
                      <ThemeIcon variant="light" size={24}>
                        <TbList size={14} />
                      </ThemeIcon>
                    }
                  />
                </Link>
              </>
            ) : null}

            {authUser.role === "candidate" ||
            (authUser && authUser.permission.hhPerm.includes(1)) ? (
              <>
                <Link href="/candidate/househeaderManage/countInMoo" passHref>
                  <NavLink
                    label="จำนวนในแต่ละหมู่"
                    active={
                      router.pathname ===
                      "/candidate/househeaderManage/countInMoo"
                    }
                    icon={
                      <ThemeIcon variant="light" size={24}>
                        <TbList size={14} />
                      </ThemeIcon>
                    }
                  />
                </Link>
              </>
            ) : null}
          </NavLink>
        ) : null}

        {authUser.role === "candidate" || authUser.role === "calias" ? (
          <NavLink
            label="อื่นๆ"
            icon={
              <ThemeIcon variant="light" size={30}>
                <TbDots size={18} />
              </ThemeIcon>
            }
            childrenOffset={42}
          >
            {authUser.role === "candidate" ||
            (authUser && authUser.permission.mPerm.includes(2)) ? (
              <a
                target="_blank"
                href="https://nearlyg0d.notion.site/1cbbfc1d436f436283f133ca0f386605"
                rel="noopener noreferrer"
              >
                <NavLink
                  label="วิธีติดตั้งโปรแกรมอ่านบัตร"
                  icon={
                    <ThemeIcon variant="light" size={24}>
                      <TbBook size={14} />
                    </ThemeIcon>
                  }
                />
              </a>
            ) : null}
          </NavLink>
        ) : null}
      </ScrollArea>
    </Navbar>
  );
};

export default SidebarContentCandidate;
