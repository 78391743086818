import React, { useState, useEffect } from "react";

import { showNotification } from "@mantine/notifications";
import { httpClient } from "./Api";

export const notification = (title: string, message: string, color: string) => {
  showNotification({
    title,
    message,
    color,
  });
};

export async function changeStatus(status: boolean, id: string, role: string) {
  try {
    const res = await httpClient.put(
      `/${role}/changeStatus/${id}`,
      {
        status: status,
      },
      {
        withCredentials: true,
      }
    );

    if (res.data.success == true) {
      notification("แก้สถานะสำเร็จ", "", "green");
      return res.data;
    } else {
      notification("แก้สถานะไม่สำเร็จ", "", "red");
      return 0;
    }
  } catch (err) {
    notification("แก้สถานะไม่สำเร็จ", `${err}`, "red");
    // console.log(err);
    return 0;
  }
}

export async function changePassword(values: object, id: string, role: string) {
  try {
    const res = await httpClient.put(`/${role}/changePassword/${id}`, values, {
      withCredentials: true,
    });

    if (res.data.success == true) {
      notification("เปลี่ยนรหัสผ่านสำเร็จ", "", "green");
      return res.data;
    } else {
      notification("เปลี่ยนรหัสผ่านไม่สำเร็จ", "", "red");
      return 0;
    }
  } catch (err) {
    notification("เปลี่ยนรหัสผ่านไม่สำเร็จ", `${err}`, "red");
    // console.log(err);
    return 0;
  }
}

export async function deleteUser(id: string, role: string) {
  try {
    const res = await httpClient.delete(`/${role}/delete/${id}`, {
      withCredentials: true,
    });

    if (res.data.success == true) {
      notification("ลบสำเร็จ", "", "green");
      return res.data;
    } else {
      notification("ลบไม่สำเร็จ", "", "red");
      return 0;
    }
  } catch (err) {
    notification("ลบไม่สำเร็จ", `${err}`, "red");
    // console.log(err);
    return 0;
  }
}
