import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { Menu, Button, Group, Header, MediaQuery, Burger } from "@mantine/core";
import { TbMenu2, TbLogout, TbKey } from "react-icons/tb";

const ChangePasswordModal = dynamic(
  () => import("@/components/ChangePasswordModal")
);

interface HeaderContentBossProps {
  authUser: {
    username: string;
    id: string;
    role: string;
    province: string;
    electorate: string;
  };
  userLogout: () => void;
  openNav: boolean;
  setOpenNav: React.Dispatch<React.SetStateAction<any>>;
}

const HeaderContentBoss = ({
  authUser,
  userLogout,
  openNav,
  setOpenNav,
}: HeaderContentBossProps) => {
  const [openPwChange, setOpenPwChange] = useState(false);

  return (
    <>
      <Header height={60} p="xs">
        <Group sx={{ height: "100%" }} px={20} position="apart">
          <Group sx={{ height: "100%" }}>
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={openNav}
                onClick={() => setOpenNav((o) => !o)}
                size="sm"
                mr="xl"
              />
            </MediaQuery>
          </Group>
          <Group sx={{ height: "100%" }}>
            {/* <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
              <Badge size="lg">
                ผู้ลงสมัครจังหวัด{authUser.province} เขต {authUser.electorate}
              </Badge>
            </MediaQuery> */}
            <Menu
              shadow="md"
              width={200}
              transition="scale-y"
              transitionDuration={150}
            >
              <Menu.Target>
                <Button rightIcon={<TbMenu2 />}>{authUser.username}</Button>
              </Menu.Target>

              <Menu.Dropdown>
                {/* <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                  <Menu.Item>
                    <Badge size="md">
                      ผู้ลงสมัครจังหวัด{authUser.province} เขต{" "}
                      {authUser.electorate}
                    </Badge>
                  </Menu.Item>
                </MediaQuery> */}
                <Menu.Item
                  icon={<TbKey size={14} />}
                  onClick={() => {
                    setOpenPwChange(true);
                  }}
                >
                  เปลี่ยนรหัสผ่าน
                </Menu.Item>
                <Menu.Item
                  color="red"
                  icon={<TbLogout size={14} />}
                  onClick={() => userLogout()}
                >
                  ออกจากระบบ
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Group>
      </Header>
      <ChangePasswordModal
        id={authUser.id}
        role={authUser.role}
        username={authUser.username}
        setOpenPwChange={setOpenPwChange}
        openPwChange={openPwChange}
      />
    </>
  );
};

export default HeaderContentBoss;
