import {
  applyMiddleware,
  legacy_createStore as createStore,
  compose,
} from "redux";
import thunk from "redux-thunk";
import { createWrapper } from "next-redux-wrapper";
const { composeWithDevTools } = require("redux-devtools-extension");

import reducers from "../reducers";

const middleware = [thunk];

const makeStore = () =>
  createStore(reducers, composeWithDevTools(applyMiddleware(...middleware)));

export const wrapper = createWrapper(makeStore);
