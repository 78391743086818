import axios from "axios";

export const httpClient = axios.create({
  withCredentials: true,
  // baseURL: `http://g-axon.work/jwtauth/api/`, //YOUR_API_URL HERE
  baseURL: process.env.NEXT_PUBLIC_API_URL, //YOUR_API_URL HERE

  headers: {
    "Content-Type": "application/json",
  },
});
